import { getLocale } from 'next-intl/server';

import { auth } from '@shared/misc';
import { AlertIcon, RenderHTML } from '@shared/ui-components';

import { Button } from '../../components';
import { ErrorType } from '../../utils/enums/error-type';

import { ErrorPageContentActions } from './error-page-content-actions';
import { getErrorPageContent } from './get-error-page-content';
import styles from './error-content.module.scss';

export const ErrorPageContent = async ({
  errorType,
  returnToBaseUrl = true,
}: {
  errorType?: ErrorType;
  returnToBaseUrl?: boolean;
}) => {
  const { accessDenied, goBackToHomepage, pageNotFound, signIn, somethingWentWrong, tryAgain } =
    await getErrorPageContent();

  const session = await auth();
  const cmsLocale = await getLocale();

  const errorMessages = {
    [ErrorType.SERVER_ERROR]: somethingWentWrong,
    [ErrorType.UNAUTHORIZED]: accessDenied,
    [ErrorType.PAGE_NOT_FOUND]: pageNotFound,
  };
  const errorMessage = errorType ? errorMessages[errorType] : pageNotFound;

  return (
    <div className={styles['error-content']}>
      <AlertIcon className={styles['error-content__icon']} />
      {errorType === ErrorType.SERVER_ERROR ? (
        <>
          <RenderHTML as="p" className={styles['error-content__message']} html={errorMessage} />
          <Button className={styles['error-content__try-again-button']} to="/">
            {tryAgain}
          </Button>
        </>
      ) : (
        <div className={styles['error-content__message']}>{errorMessage}</div>
      )}
      {errorType !== ErrorType.SERVER_ERROR && (
        <div className={styles['error-content__actions']}>
          <ErrorPageContentActions
            actionsCmsContent={{ goBackToHomepage, signIn }}
            locale={cmsLocale}
            returnToBaseUrl={returnToBaseUrl}
            shouldShowSignInButton={session === null}
          />
        </div>
      )}
    </div>
  );
};
