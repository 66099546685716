import { AlertIcon, RenderHTML } from '@shared/ui-components';

import { Button } from '../../components';

import styles from './error-content.module.scss';

export const ErrorPageContentClient = ({
  message,
  reset,
}: {
  message?: string;
  reset?: () => void;
}) => {
  const tryAgainLabel = 'Try again';

  return (
    <div className={styles['error-content']}>
      <AlertIcon className={styles['error-content__icon']} />
      <RenderHTML as="p" className={styles['error-content__message']} html={message} />
      <div className={styles['error-content__actions']}>
        {reset && <Button onClick={() => reset()}>{tryAgainLabel}</Button>}
      </div>
    </div>
  );
};
