import { NextIntlClientProvider } from 'next-intl';
import { getLocale } from 'next-intl/server';
import { type PropsWithChildren } from 'react';

import { auth, parseJwtToken, SessionProvider } from '@shared/misc';

import { Footer, GtmScript, Header, Providers } from '../components';
import { GlobalCmsContentContextProvider } from '../components/providers/global-cms-context';
import { UserContextProvider } from '../components/providers/user-context';
import { getGlobalCmsContent } from '../utils/data';
import { getCustomerProfileAdB2CObjectId } from '../utils/data/get-customer-profile-ad-b2c-object-id';

import { getFooterContent } from './footer-content/get-footer-content';
import styles from './layout-content.module.scss';

export const LayoutContent = async ({ children }: PropsWithChildren) => {
  const isProductionBuild = process.env.NODE_ENV !== 'development';
  const globalCmsContent = await getGlobalCmsContent();
  const footerCmsContent = await getFooterContent();
  const locale = await getLocale();

  const session = await auth();
  const parsedToken = session?.accessToken ? parseJwtToken(session.accessToken) : undefined;

  const customerProfileAdB2CObjectIdResponse = session?.personId
    ? await getCustomerProfileAdB2CObjectId({ personId: session.personId })
    : undefined;

  const userId =
    session?.personIdBeforeImpersonation && session?.personId
      ? customerProfileAdB2CObjectIdResponse?.objectId
      : parsedToken
      ? parsedToken.sub
      : undefined;

  return (
    <NextIntlClientProvider locale={locale}>
      <SessionProvider>
        <UserContextProvider userId={userId}>
          <GlobalCmsContentContextProvider cmsContent={globalCmsContent}>
            <Providers>
              <div className={styles['main-wrapper']}>
                <Header />
                {process.env.GTM_ID && isProductionBuild && <GtmScript />}
                <main className={styles.main}>{children}</main>
                <div className={styles['main__footer-wrapper']}>
                  <Footer cmsContent={footerCmsContent} locale={locale} />
                </div>
              </div>
            </Providers>
          </GlobalCmsContentContextProvider>
        </UserContextProvider>
      </SessionProvider>
    </NextIntlClientProvider>
  );
};
