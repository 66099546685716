import { auth } from '@shared/misc';

import { fetchData } from '../helpers/fetch-data';
import { getApiHeaders } from '../helpers/get-headers';
import { withErrorHandling } from '../helpers/with-error-handling';

// eslint-disable-next-line canonical/id-match
export const getCustomerProfileAdB2CObjectId = async ({
  personId,
}: {
  personId?: number;
}): Promise<{ objectId: string }> => {
  const session = await auth();

  return withErrorHandling(
    await fetchData({
      apiUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}/AdB2CObjectId`,
      headers: getApiHeaders(session),
    }),
    // eslint-disable-next-line no-template-curly-in-string
    '${process.env.NEXT_PUBLIC_API_BASE_URL}/${process.env.NEXT_PUBLIC_API_API_ROUTE}/CustomerProfile/${personId}/AdB2CObjectId'
  );
};
