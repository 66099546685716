/* eslint-disable complexity */

import { QueryClient } from '@tanstack/react-query';

import { useFooterContentQuery } from '../../utils/data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../../utils/data/graphql/utils/get-content-in-current-language';
import { isNonNullable } from '../../utils/helpers';

export const getFooterContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: useFooterContentQuery.fetcher(),
    queryKey: useFooterContentQuery.getKey(),
  });

  const footerItemsData = await getContentInCurrentLanguage(data.SiteSettingsPage?.items);
  const footerItems = { ...footerItemsData?.[0] };

  const socialLinks = footerItemsData?.[0]?.FooterSocialLinks?.map((item) =>
    item?.ContentLink?.Expanded?.__typename === 'SvgLinkBlock' ? item?.ContentLink?.Expanded : null
  )
    ?.filter(isNonNullable)
    ?.map((item) => ({
      displayText: item.Name || '',
      iconClass: item.IconClass || '',
      linkToContent: item.LinkToContent || '',
      target: item.Target || '',
    }));

  const helpfulLinks = footerItemsData?.[0]?.HelpfulLinks?.map((item) =>
    item?.ContentLink?.Expanded?.__typename === 'LinkBlock' ? item?.ContentLink?.Expanded : null
  )
    ?.filter(isNonNullable)
    ?.map((item) => ({
      displayText: item.DisplayText || '',
      linkToContent: item.LinkToContent || '',
      relativePath: item.RelativePath || '',
      target: item.Target || '',
    }));

  const chapterSitesLinks = footerItemsData?.[0]?.ChapterSitesLinks?.map((item) =>
    item?.ContentLink?.Expanded?.__typename === 'LinkBlock' ? item?.ContentLink?.Expanded : null
  )
    ?.filter(isNonNullable)
    ?.map((item) => ({
      displayText: item.DisplayText || '',
      linkToContent: item.LinkToContent || '',
      relativePath: item.RelativePath || '',
      target: item.Target || '',
    }));

  return {
    address: footerItems.Address || '325 W. Touhy Avenue, <br />Park Ridge, IL 60068<br />USA',
    appStoreAltText: footerItems.AppStoreLinkAriaLabel || 'Download on the App Store',
    appStoreLink: footerItems.AppStoreLink || '',
    chapterSitesHeader: footerItems.ChapterSitesHeader || 'Chapter Sites',
    chapterSitesLinks: chapterSitesLinks || undefined,
    contactUsLink: {
      displayText: footerItems.ContactUsLink?.DisplayText || 'Contact Us',
      linkToContent:
        footerItems.ContactUsLink?.LinkToContent || 'https://www.mdrt.org/dashboard/about/', // TODO: this link is returned as absolute path from Optimizely
      target: footerItems.ContactUsLink?.Target || '_self',
    },
    copyright:
      footerItems.Copyright || `Copyright ${new Date().getFullYear()} Million Dollar Round Table®`,
    disclaimerLink: {
      displayText: footerItems.DisclaimerLink?.DisplayText || 'Disclaimer',
      linkToContent:
        footerItems.DisclaimerLink?.LinkToContent || 'https://www.mdrt.org/disclaimer/',
      target: footerItems.DisclaimerLink?.Target || '_self',
    },
    footerLogoAltText: footerItems.FooterLogo?.AltText || 'Million Dollar Round Table',
    footerLogoLink: {
      displayText: footerItems.FooterLogoLink?.DisplayText || 'Homepage',
      linkToContent: footerItems.FooterLogoLink?.LinkToContent || '/',
      target: footerItems.FooterLogoLink?.Target || '_self',
    },
    googlePlayAltText: footerItems.GooglePlayLinkAriaLabel || 'Get it on Google Play',
    googlePlayLink: footerItems.GooglePlayLink || '',
    helpfulLinks: helpfulLinks || undefined,
    helpfulLinksHeader: footerItems.HelpfulLinksHeader || 'Helpful Links',
    mdrtAppsLabel: footerItems.MdrtAppsHeader || 'MDRT Apps',
    phoneNumberLink: {
      displayText: footerItems.PhoneNumberLink?.DisplayText || '+1 847 692 6378',
      linkToContent: footerItems.PhoneNumberLink?.LinkToContent || 'tel:18476926378',
      target: footerItems.PhoneNumberLink?.Target || '_blank',
    },
    privacyLink: {
      displayText: footerItems.PrivacyLink?.DisplayText || 'Privacy',
      linkToContent: footerItems.PrivacyLink?.LinkToContent || 'https://www.mdrt.org/privacy/',
      target: footerItems.PrivacyLink?.Target || '_self',
    },
    socialLinks: socialLinks || undefined,
  };
};

export type FooterContent = Awaited<Promise<PromiseLike<ReturnType<typeof getFooterContent>>>>;
