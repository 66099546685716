import { QueryClient } from '@tanstack/react-query';

import { useErrorPageContentQuery } from '../../utils/data/graphql/_generated/gql-generated';
import { getContentInCurrentLanguage } from '../../utils/data/graphql/utils/get-content-in-current-language';

export const getErrorPageContent = async () => {
  const queryClient = new QueryClient();

  const data = await queryClient.fetchQuery({
    queryFn: useErrorPageContentQuery.fetcher(),
    queryKey: useErrorPageContentQuery.getKey(),
  });

  const siteSettingsPageItems = await getContentInCurrentLanguage(data.SiteSettingsPage?.items);

  const cmsContent = {
    ...siteSettingsPageItems?.[0],
  };

  return {
    accessDenied: cmsContent?.AccessDenied || "You don't have access to this page.",
    goBackToHomepage: cmsContent?.GoBackToHomepage || 'Go back to Homepage',
    pageNotFound: cmsContent?.PageNotFoundErrorMessage || 'Page was not found.',
    signIn: cmsContent?.LogInPrompt || 'Sign In',
    somethingWentWrong:
      cmsContent?.SomethingWentWrong ||
      'Server Error.<br />Please come back later or contact us for assistance:<br />Phone: +1 847-692-6378<br />info@mdrt.org',
    tryAgain: cmsContent?.TryAgainErrorMessage || 'Try again',
  };
};

export type ErrorPageContent = Awaited<
  Promise<PromiseLike<ReturnType<typeof getErrorPageContent>>>
>;
